import { Match, Switch, createEffect, createRenderEffect, splitProps, untrack } from "solid-js";
import { Ctrl, log, state } from ":mods";
import { ShowMaterial } from "../../show-material";
import { MaterialFilter } from "./material-filter";
import { unwrap } from "solid-js/store";
import { ASubmoduleMaterial, ShowSubmissionStudentViewProps } from "../../../models";
import { getSubmissionsStudent } from "../../../apis";
import { StudentSubmodulePreview } from "./preview";
import { getMaterialType } from "src/modules/courses/methods";
export function StudentSubmodule(props: ShowSubmissionStudentViewProps) {
  const [local, others] = splitProps(props, ["$", "type", "arr", "events", "applicationID"]);
  const Layer = local.$.actions.createLayer({
    backMsg: "Back To Submission",
    // allowLoopItemsOnNextAndPrevious: true,
    skipNavigation: true,
    events: {
      backTo: undefined,
      afterBackTo() {
        local.events?.onBack?.();
      },
      onNextNotFound: () => {
        local.$.actions.pushToast({
          type: "info",
          message: "This is the last item in this submodule!",
        });
      },
    },
  });

  local.$.actions.clearLayout();
  return (
    <>
      <Layer
        items={local.arr?.map((_material) => {
          const material = unwrap(_material);
          // const is_tp22 = material?.design_name?.toLowerCase().startsWith("tp22");
          // if (is_tp22) {
          // name = name.split("|")[0];
          // }
          let name = material.name;

          let idle_icon = "icon-local-submodule-material:question-idle";
          let active_icon = "icon-local-submodule-material:question-active";
          if (local.type === "tasks") {
            idle_icon = "icon-local-submodule-material:task-idle";
            active_icon = "icon-local-submodule-material:task-active";
          }
          return {
            title: name,
            element: () => (
              <StudentSubmodulePreview
                $={local.$}
                applicationID={local.applicationID}
                id={material.id}
                type={local.type === "question" ? "question" : "task"}
              />
            ),

            icon: {
              idle: idle_icon,
              active: active_icon,
            },
            titleClass: "!text-0.8rem",
            subtitleClass: "!text-0.6rem",
          };
        })}
      />
    </>
  );
}
